module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"m-and-m-consulting","short_name":"M&M Consulting","start_url":"/","background_color":"#FFFFFF","theme_color":"#68008C","display":"standalone","icon":"/opt/build/repo/src/images/LogoTransparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6d456af49d4b5773c9b9e5162e5c7e0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
